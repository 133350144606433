import { computed } from 'vue'
import emitter from '/~/core/emitter'
import { useExtensions } from '/~/composables/extensions'
import { useUser } from '/~/composables/user'

const moduleConfig = computed(() => {
  const { getConfigByName } = useExtensions()

  return getConfigByName(
    'fraud-and-aml-screening'
  ) as FraudAndAmlScreening.ModuleConfig | null
})
const isFraudAndAmlScreeningEnabled = computed(() =>
  Boolean(moduleConfig.value)
)
const failedAssessmentRoles = computed(() => {
  return (moduleConfig.value?.failedAssessmentRoles ?? '').split(',')
})

emitter.on(
  'notifications:fraud_and_aml_assessment_result.pass',
  verificationUpdateHandler
)
emitter.on(
  'notifications:fraud_and_aml_assessment_result.fail',
  verificationUpdateHandler
)

function verificationUpdateHandler() {
  if (!isFraudAndAmlScreeningEnabled.value) {
    return
  }

  const { fetchUserDetails } = useUser()

  fetchUserDetails()
}

export function useFraudAndAmlScreening() {
  return {
    isFraudAndAmlScreeningEnabled,
    failedAssessmentRoles,
  }
}

export function formatBusinessNumber(value: string) {
  return value
}

export function parseBsb(
  value: string | number,
  options = {
    showLabel: false,
  }
) {
  const result: Record<string, string | null> = {
    institutionNumber: null,
    transitNumber: null,
  }

  if (!value) {
    return result
  }

  const institutionNumber = String(value).substring(0, 3)
  const transitNumber = String(value).substring(3)

  if (institutionNumber?.length === 3 && transitNumber?.length === 5) {
    result.institutionNumber =
      (options.showLabel ? 'Institution Number: ' : '') + institutionNumber
    result.transitNumber =
      (options.showLabel ? 'Transit Number: ' : '') + transitNumber
  }

  return result
}

export function formatBsb(value: string | number) {
  if (value) {
    const matches = String(value).match(/.{1,3}/g)

    if (matches) {
      return matches.join('-')
    }
  }

  return value
}

export const bsbLength = 8

export function postfixBsb(value: string) {
  return value.length === bsbLength ? value.slice(-3) : ''
}

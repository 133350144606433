import dayjs from 'dayjs/esm'
import Vue, { watch } from 'vue'
import { Location } from 'vue-router'
import {
  Address,
  ApiResponseData,
  Order,
  PaymentMethod,
  PaymentOrderList,
  ProgramFees,
} from '/~/types/api'
import api from '/~/core/api'
import bottomSheet from '/~/core/bottom-sheet'
import emitter from '/~/core/emitter'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { createDate } from '/~/utils/format/date'
import { roundFigure } from '/~/utils/format/numeric'
import {
  FlowType,
  SelectedPaymentMethodBankAccount,
  SelectedPaymentMethodCreditCard,
  SelectedPaymentMethodEwallet,
  SelectedPaymentMethodPayId,
  SelectedPaymentMethodPoints,
} from '/~/composables/checkout/checkout-types'
import { getTotalFeesForSource } from '/~/composables/checkout/fees'
import {
  PAYMENT_METHODS_SORT_ORDER,
  PaymentMethodType,
  useVerifyCard,
} from '/~/composables/payment-methods'
import { ModalsConfig } from '/~/composables/payment-methods/use-card-verification'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

const { pointsBalance, isPaymentOrderPoints } = usePoints()

const ERROR_PAYMENT_CANCELLED = 'Payment cancelled'

const DEFAULT_DONE_ROUTE = {
  name: 'home',
}

export type PayWith = {
  payId: SelectedPaymentMethodPayId | null
  card: SelectedPaymentMethodCreditCard | null
  points: SelectedPaymentMethodPoints | null
  ewallet: SelectedPaymentMethodEwallet | null
  bankAccount: SelectedPaymentMethodBankAccount | null
  coupons: {
    promotionCode: string
  }[]
}

export type CardVerificationConfig = {
  modalsConfig?: ModalsConfig
}

interface PromotionCodeRawData {
  promotionCode: string
  amount: number
}

type CheckoutPayload = {
  address?: Address
  cardVerification?: CardVerificationConfig
  onPreview?: (
    result: any,
    actions: {
      onConfirm: () => void
      onCancel: () => void
    }
  ) => Promise<void>
  onComplete?: (result: any) => Promise<void>
}

interface TransactionFeesPayload {
  paymentSources: {
    paymentMethodId: string
    amount: string
    points?: string
  }[]
  paymentDestinations?: {
    payeeId: string
    amount: string
  }[]
}

export abstract class Checkout {
  flowType: FlowType | null = null
  date: dayjs.Dayjs | null = null
  address: Address | null = null
  programFeesData?: ProgramFees
  programFees = 0
  programFeesPercentage = 0
  loadingProgramFees = false
  transactionFees = 0
  loadingTransactionFees = false
  itemsCount = 1
  description = null
  fetching = false
  submitting = false
  amount = 0
  securityToken: string | null = null
  cvv: string | null = null
  feesSuccess = false
  cardVerificationType: 'default' | 'payees' = 'payees' as const
  payWith: PayWith = {
    card: null,
    points: null,
    ewallet: null,
    bankAccount: null,
    payId: null,
    coupons: [],
  }

  isCouponVerifying = false
  confirmation: { order?: Order; loading: boolean; amount?: number } = {
    loading: false,
    order: undefined,
    amount: undefined,
  }

  previewURL: string | null = null
  isSchedulingAllowed = true
  orderType = FlowType.purchase
  termsAccepted = false

  routes: {
    [K: string]: Location | null
  } = {
    done: DEFAULT_DONE_ROUTE,
    root: null,
  }

  constructor() {
    if (this.constructor === Checkout) {
      throw new Error('Abstract classes can not be instantiated')
    }

    watch(
      () => {
        return this.amount
      },
      () => {
        if (this.amount > 0) {
          this.updatePromotionCodes()
        } else {
          this.reset()
        }
      }
    )

    emitter.on('payment-methods:removed', (method: PaymentMethod) => {
      if (!method) {
        return
      }

      switch (method.id) {
        case this.selectedCard?.id:
          this.selectedCard = null
          this.getTransactionFees()

          break
        case this.selectedBankAccount?.id:
          this.selectedBankAccount = null
          this.getTransactionFees()

          break
      }
    })
  }

  get checkoutItemsCount() {
    return this.itemsCount
  }

  get doneRoute() {
    return this.routes.done
  }

  // reassign it in the child class
  get rootRoute() {
    if (!this.routes.root) {
      console.debug(
        `Property "rootRoute" is not specified for the class ${this.constructor.name}`
      )
    }

    return this.routes.root
  }

  get isCouponsAllowed() {
    return false
  }

  get checkoutURL(): string | null {
    return null
  }

  get transactionFeesURL() {
    return '/v3/payment-methods/fees'
  }

  get subFlowType() {
    return ''
  }

  get isAddressSelectionAllowed() {
    return false
  }

  get programFeesRounded() {
    return roundFigure(this.programFees)
  }

  get transactionFeesRounded() {
    return roundFigure(this.transactionFees)
  }

  get shippingFees() {
    return 0
  }

  get totalFees() {
    return roundFigure(
      this.transactionFees + this.programFees + this.shippingFees
    )
  }

  get loadingFees() {
    return this.loadingProgramFees || this.loadingTransactionFees
  }

  get subTotal() {
    return roundFigure(this.amount)
  }

  get subTotalNoCoupons() {
    return this.subTotal - this.payWithCoupons
  }

  get subTotalWithProgramFees() {
    return roundFigure(this.subTotal + this.programFees)
  }

  get subTotalWithProgramFeesNoCoupons() {
    return this.subTotalWithProgramFees - this.payWithCoupons
  }

  get subTotalWithFees() {
    return roundFigure(this.subTotalWithProgramFees + this.transactionFees)
  }

  get total() {
    return this.subTotalWithProgramFees + this.transactionFees
  }

  get selectedAmount() {
    return roundFigure(
      this.payWithPoints +
        this.payWithEwallet +
        this.payWithCoupons +
        this.payWithCard +
        this.payWithBankAccount
    )
  }

  get termsShouldBeAccepted() {
    return false
  }

  get isTermsAccepted() {
    return !this.termsShouldBeAccepted || this.termsAccepted
  }

  get readyToPay() {
    if (this.selectedPayId) {
      return true
    }

    const isCoveredBySubMethods =
      this.selectedAmount >= this.subTotalWithProgramFees &&
      this.subTotalWithProgramFees > 0
    const feesSuccess =
      this.feesSuccess || this.payWithCoupons === this.subTotalWithProgramFees
    const isLocked = this.loadingFees || !feesSuccess || this.isCouponVerifying
    const isValidDateOrNotSelected = dayjs(this.date).isValid() || !this.date

    return isCoveredBySubMethods && !isLocked && isValidDateOrNotSelected
  }

  get burnPointsRate(): number {
    throw new Error(
      `Property "burnPointsRate" is not implemented for the class ${this.constructor.name}`
    )
  }

  get isReadyForPayment() {
    return true
  }

  get isEnoughPointsOrder() {
    return (
      pointsBalance.value > this.subTotalWithProgramFees / this.burnPointsRate
    )
  }

  get pointsEarned() {
    return 0
  }

  get isOrderPoints() {
    return isPaymentOrderPoints.value
  }

  get isScheduledPayment() {
    return Boolean(this.date)
  }

  get isActiveScheduledPayment() {
    return Boolean(
      this.isScheduledPayment && createDate(this.date).isAfter(new Date())
    )
  }

  get isPointsDisabled() {
    const { splitPaymentCombinationsAllowed } = useProvider()

    return !splitPaymentCombinationsAllowed(
      'points',
      this.selectedPaymentMethods
    )
  }

  get isCreditCardsDisabled() {
    const { splitPaymentCombinationsAllowed } = useProvider()

    return !splitPaymentCombinationsAllowed(
      'creditCard',
      this.selectedPaymentMethods
    )
  }

  get isBankAccountsDisabled() {
    const { splitPaymentCombinationsAllowed } = useProvider()

    return !splitPaymentCombinationsAllowed(
      'directDebit',
      this.selectedPaymentMethods
    )
  }

  get isEwalletDisabled() {
    const { splitPaymentCombinationsAllowed } = useProvider()

    return !splitPaymentCombinationsAllowed(
      'ewallet',
      this.selectedPaymentMethods
    )
  }

  get selectedPaymentMethods() {
    const methods = {
      payId: Boolean(this.payWith.payId),
      points: Boolean(this.payWith.points),
      creditCard: Boolean(this.payWith.card),
      directDebit: Boolean(this.payWith.bankAccount),
      ewallet: Boolean(this.payWith.ewallet),
    }

    return Object.fromEntries(
      Object.entries(methods).filter(([_, isEnabled]) => isEnabled)
    )
  }

  get hasSelectedPaymentMethods() {
    return Boolean(
      this.payWith.payId ||
        this.payWith.card ||
        this.payWith.points ||
        this.payWith.ewallet ||
        this.payWith.bankAccount ||
        this.payWith.coupons?.length > 0
    )
  }

  get selectedPayId() {
    return this.payWith.payId
  }

  set selectedPayId(val) {
    const { splitPaymentOrderAllowed } = useProvider()

    this.payWith.payId = val

    if (this.flowType && !splitPaymentOrderAllowed(this.flowType) && val) {
      this.resetCard()
      this.resetBankAccount()
      this.resetPoints()
      this.resetEwallet()

      emitter.emit('checkout-method-selected')
    }
  }

  get selectedPoints() {
    return this.payWith.points
  }

  set selectedPoints(val) {
    const { splitPaymentOrderAllowed } = useProvider()

    if (val) {
      const { usePoints: pointsAmount, calculatedAmount } = val

      if (pointsAmount === undefined && calculatedAmount !== undefined) {
        val.usePoints = Math.ceil(calculatedAmount / this.burnPointsRate)
      } else if (calculatedAmount === undefined && pointsAmount !== undefined) {
        val.calculatedAmount = roundFigure(pointsAmount * this.burnPointsRate)
      }
    }

    this.payWith.points = val

    if (
      !this.flowType ||
      ((!splitPaymentOrderAllowed(this.flowType) || this.isScheduledPayment) &&
        val)
    ) {
      this.resetEwallet()
      this.resetCard()
      this.resetBankAccount()

      emitter.emit('checkout-method-selected')
    }
  }

  get selectedEwallet() {
    return this.payWith.ewallet
  }

  set selectedEwallet(val) {
    const { splitPaymentOrderAllowed } = useProvider()

    this.payWith.ewallet = val

    if (
      !this.flowType ||
      ((!splitPaymentOrderAllowed(this.flowType) || this.isScheduledPayment) &&
        val)
    ) {
      this.resetCard()
      this.resetPoints()
      this.resetBankAccount()

      emitter.emit('checkout-method-selected')
    }
  }

  get selectedCoupons() {
    return this.payWith.coupons
  }

  set selectedCoupons(val) {
    this.updateAmountsOnCoupons(val)
    this.payWith.coupons = val
    this.getTransactionFees()
  }

  get selectedCard() {
    return this.payWith.card
  }

  set selectedCard(val) {
    const { splitPaymentOrderAllowed } = useProvider()

    this.payWith.card = val

    if (!this.flowType || (!splitPaymentOrderAllowed(this.flowType) && val)) {
      this.resetBankAccount()
      this.resetPoints()
      this.resetEwallet()

      emitter.emit('checkout-method-selected')
    }
  }

  get selectedBankAccount() {
    return this.payWith.bankAccount
  }

  set selectedBankAccount(val) {
    const { splitPaymentOrderAllowed } = useProvider()

    this.payWith.bankAccount = val

    if (!this.flowType || (!splitPaymentOrderAllowed(this.flowType) && val)) {
      this.resetCard()
      this.resetPoints()
      this.resetEwallet()

      emitter.emit('checkout-method-selected')
    }
  }

  get hasCoupons() {
    return this.selectedCoupons?.length > 0
  }

  get payWithPayId() {
    if (!this.selectedPayId) {
      return 0
    }

    if (this.payWithCoupons >= this.subTotalWithProgramFees) {
      return 0
    }

    if (this.selectedPayId.calculatedAmount >= this.subTotalWithProgramFees) {
      return this.subTotalWithProgramFees
    }

    const calculatedAmount =
      this.subTotalWithProgramFees -
      this.selectedPayId.calculatedAmount -
      this.payWithCoupons

    if (calculatedAmount >= 0) {
      return roundFigure(this.selectedPayId.calculatedAmount)
    } else {
      return roundFigure(this.subTotalWithProgramFees - this.payWithCoupons)
    }
  }

  get payWithPoints() {
    if (!this.selectedPoints) {
      return 0
    }

    if (this.payWithCoupons >= this.subTotalWithProgramFees) {
      return 0
    }

    if (this.selectedPoints.calculatedAmount >= this.subTotalWithProgramFees) {
      return this.subTotalWithProgramFees
    }

    const calculatedAmount =
      this.subTotalWithProgramFees -
      this.selectedPoints.calculatedAmount -
      this.payWithCoupons

    if (calculatedAmount >= 0) {
      return roundFigure(this.selectedPoints.calculatedAmount)
    } else {
      return roundFigure(this.subTotalWithProgramFees - this.payWithCoupons)
    }
  }

  get payWithEwallet() {
    if (!this.selectedEwallet) {
      return 0
    }

    if (this.payWithCoupons >= this.subTotalWithProgramFees) {
      return 0
    }

    if (this.selectedEwallet.calculatedAmount >= this.subTotalWithProgramFees) {
      return this.subTotalWithProgramFees
    }

    const calculatedAmount =
      this.subTotalWithProgramFees -
      this.selectedEwallet.calculatedAmount -
      this.payWithCoupons

    if (calculatedAmount >= 0) {
      return roundFigure(this.selectedEwallet.calculatedAmount)
    } else {
      return roundFigure(this.subTotalWithProgramFees - this.payWithCoupons)
    }
  }

  get payWithCoupons() {
    if (!this.selectedCoupons) {
      return 0
    } else {
      const couponsTotal = this.selectedCoupons.reduce((sum, i: any) => {
        return sum + (Number(i.amount) ?? 0)
      }, 0)

      return Math.min(couponsTotal, this.subTotalWithProgramFees)
    }
  }

  get payWithCard() {
    if (!this.selectedCard) {
      return 0
    }

    if (this.payWithCoupons >= this.subTotalWithProgramFees) {
      return 0
    }

    if (this.selectedCard.calculatedAmount >= this.subTotalWithProgramFees) {
      return this.subTotalWithProgramFees
    }

    const calculatedAmount =
      this.subTotalWithProgramFees -
      this.selectedCard.calculatedAmount -
      this.payWithCoupons

    if (calculatedAmount >= 0) {
      return roundFigure(this.selectedCard.calculatedAmount)
    } else {
      return roundFigure(this.subTotalWithProgramFees - this.payWithCoupons)
    }
  }

  get payWithBankAccount() {
    if (!this.selectedBankAccount) {
      return 0
    }

    if (this.payWithCoupons >= this.subTotalWithProgramFees) {
      return 0
    }

    if (
      this.selectedBankAccount.calculatedAmount >= this.subTotalWithProgramFees
    ) {
      return this.subTotalWithProgramFees
    }

    const calculatedAmount =
      this.subTotalWithProgramFees -
      this.selectedBankAccount.calculatedAmount -
      this.payWithCoupons

    if (calculatedAmount >= 0) {
      return roundFigure(this.selectedBankAccount.calculatedAmount)
    } else {
      return roundFigure(this.subTotalWithProgramFees - this.payWithCoupons)
    }
  }

  get isCardOrBankAccountMethodSelected() {
    return (
      this.selectedPaymentMethods.creditCard ||
      this.selectedPaymentMethods.directDebit
    )
  }

  get multipleSourcesSelected() {
    const selectedPaymentMethods = {
      points: Boolean(this.selectedPoints && this.payWithPoints),
      card: Boolean(this.selectedCard && this.payWithCard),
      bankAccount: Boolean(this.selectedBankAccount && this.payWithBankAccount),
      ewallet: Boolean(this.selectedEwallet && this.payWithEwallet),
      coupons: this.payWithCoupons > 0,
    }

    return (
      Object.entries(selectedPaymentMethods).filter(
        ([_, isSelected]) => isSelected
      ).length > 1
    )
  }

  get isConfirmationLoaded() {
    return Boolean(!this.confirmation.loading && this.confirmation.order)
  }

  getAddAddressRoute(): Location {
    return { hash: '#profile-add-address' }
  }

  getEditAddressRoute(id: string): Location {
    return { hash: `#profile-add-address/${id}` }
  }

  resetEwallet() {
    this.payWith.ewallet = null
  }

  resetCard() {
    this.payWith.card = null
    this.securityToken = null
    this.cvv = null
  }

  resetPayId() {
    this.payWith.payId = null
  }

  resetPoints() {
    this.payWith.points = null
  }

  resetBankAccount() {
    this.payWith.bankAccount = null
  }

  resetPaymentMethods() {
    this.resetPayId()
    this.resetEwallet()
    this.resetCard()
    this.resetPoints()
    this.resetBankAccount()

    this.transactionFees = 0
    this.loadingTransactionFees = false
  }

  resetCoupons() {
    this.payWith.coupons = []
  }

  resetCouponsAndPaymentMethods() {
    this.resetPaymentMethods()
    this.resetCoupons()
  }

  reset() {
    this.resetCouponsAndPaymentMethods()

    this.amount = 0
    this.submitting = false
    this.date = null
    this.address = null
    this.programFeesData = undefined
    this.programFees = 0
    this.loadingProgramFees = false
    this.description = null
    this.isSchedulingAllowed = true
    this.termsAccepted = false
  }

  initPayment(payment: any) {
    this.reset()
    if (payment?.doneRoute) {
      this.routes.done = payment.doneRoute
    }
    if (payment?.rootRoute) {
      this.routes.root = payment.rootRoute
    }
  }

  getTransactionFeesPayload(): TransactionFeesPayload {
    const points = this.selectedPoints
    const ewallet = this.selectedEwallet
    const card = this.selectedCard
    const bankAccount = this.selectedBankAccount

    const payload: TransactionFeesPayload = {
      paymentSources: [],
    }

    if (this.payWithCard && card) {
      payload.paymentSources.push({
        paymentMethodId: card.id,
        amount: `${roundFigure(this.payWithCard)}`,
      })
    }

    if (this.payWithBankAccount && bankAccount) {
      payload.paymentSources.push({
        paymentMethodId: bankAccount.id,
        amount: `${roundFigure(this.payWithBankAccount)}`,
      })
    }

    if (this.payWithPoints && points) {
      payload.paymentSources.push({
        paymentMethodId: points.id,
        amount: `${roundFigure(this.payWithPoints)}`,
        points: `${points.usePoints}`,
      })
    }

    if (this.payWithEwallet && ewallet) {
      payload.paymentSources.push({
        paymentMethodId: ewallet.id,
        amount: `${roundFigure(this.payWithEwallet)}`,
      })
    }

    return payload
  }

  async getTransactionFees() {
    const amount = this.subTotal
    const points = this.selectedPoints
    const ewallet = this.selectedEwallet
    const card = this.selectedCard
    const bankAccount = this.selectedBankAccount

    this.feesSuccess = false
    this.transactionFees = 0

    if (
      (!card && !bankAccount && !points && !ewallet) ||
      amount === 0 ||
      roundFigure(this.selectedAmount) < this.subTotalWithProgramFees
    ) {
      this.transactionFees = 0
      this.feesSuccess = true

      return
    }

    this.loadingTransactionFees = true

    try {
      const payload = this.getTransactionFeesPayload()

      const { data } = await api.post<any>(this.transactionFeesURL, payload)

      if (data) {
        const { fee, paymentSources } = data

        this.transactionFees = Number(fee)

        if (this.payWithPoints && points) {
          const totalPointsFees = getTotalFeesForSource(
            paymentSources,
            points.id
          )

          this.payWith.points = {
            ...points,
            fee: totalPointsFees,
          }
        }

        if (this.payWithEwallet && ewallet) {
          const totalEwalletFees = getTotalFeesForSource(
            paymentSources,
            ewallet.id
          )

          this.payWith.ewallet = {
            ...ewallet,
            fee: totalEwalletFees,
          }
        }

        if (this.payWithCard && card) {
          const cardSource = paymentSources.find(
            (item: any) => item.paymentMethodId === card.id
          )
          const totalCardFees = getTotalFeesForSource(paymentSources, card.id)

          this.payWith.card = {
            ...card,
            fee: totalCardFees,
            percentageFeeRate: Number(cardSource?.percentageFeeRate ?? 0),
          }
        }

        if (this.payWithBankAccount && bankAccount) {
          const bankAccountSource = paymentSources.find(
            (item: any) => item.paymentMethodId === bankAccount.id
          )
          const totalBankAccountFees = getTotalFeesForSource(
            paymentSources,
            bankAccount.id
          )

          this.payWith.bankAccount = {
            ...bankAccount,
            fee: totalBankAccountFees,
            percentageFeeRate: Number(
              bankAccountSource?.percentageFeeRate ?? 0
            ),
          }
        }

        this.feesSuccess = true
      }
    } catch (error: any) {
      Vue.notify({
        text: error?.paymentSources?.[0] || 'Fee calculation failed',
        type: 'error',
        duration: 10000,
      })
    } finally {
      this.loadingTransactionFees = false
    }
  }

  async getProgramFees() {}

  getPayload() {
    const payload: any = {
      paymentSources: this.getPaymentSources(),
    }

    if (this.description) {
      payload.paymentSourcesStatementDescriptor = this.description
    }

    if (this.date) {
      payload.scheduledAt = this.date.utc(true)
    }

    if (this.payWithCoupons > 0) {
      payload.promotionCodes = this.selectedCoupons
    }

    return payload
  }

  getPaymentSources() {
    const { securityToken, cvv } = this
    const paymentSources = []

    if (this.selectedCard && this.payWithCard) {
      paymentSources.push({
        paymentMethodId: this.selectedCard.id,
        amount: `${roundFigure(this.payWithCard)}`,
        securityToken,
        verificationCode: cvv,
      })
    }

    if (this.selectedBankAccount && this.payWithBankAccount) {
      paymentSources.push({
        paymentMethodId: this.selectedBankAccount.id,
        amount: `${roundFigure(this.payWithBankAccount)}`,
      })
    }

    if (this.selectedPayId && this.payWithPayId) {
      paymentSources.push({
        paymentMethodId: this.selectedPayId.id,
        amount: `${roundFigure(this.payWithPayId)}`,
      })
    }

    if (this.selectedPoints && this.payWithPoints) {
      paymentSources.push({
        paymentMethodId: this.selectedPoints.id,
        amount: `${roundFigure(this.payWithPoints)}`,
        points: `${this.selectedPoints.usePoints}`,
      })
    }

    if (this.selectedEwallet && this.payWithEwallet) {
      paymentSources.push({
        paymentMethodId: this.selectedEwallet.id,
        amount: `${roundFigure(this.payWithEwallet)}`,
      })
    }

    return paymentSources
  }

  async checkout({ onPreview, ...payload }: CheckoutPayload) {
    this.submitting = true

    try {
      const previewResult = await this.preview()

      if (onPreview) {
        await onPreview?.(previewResult, {
          onConfirm: () => this.onPreviewConfirm(payload),
          onCancel: () => this.reset(),
        })
      } else {
        this.onPreviewConfirm(payload)
      }
    } catch {
      this.submitting = false
    }
  }

  async onPreviewConfirm({
    address,
    cardVerification,
    onComplete,
  }: Omit<CheckoutPayload, 'onPreview'>) {
    const { verifyCard } = useVerifyCard()

    try {
      if (this.selectedCard && !this.isScheduledPayment) {
        const cardVerificationPayload = this.getCardVerificationPayload({
          card: this.selectedCard,
          address,
          cardVerification,
        })

        const { securityToken, cvv } = await verifyCard(cardVerificationPayload)

        this.securityToken = securityToken
        this.cvv = cvv
      }

      const result: any = await this.pay()

      await onComplete?.(result)

      this.finishPayment(result)
    } catch (error: any) {
      console.error('checkout', error)
    } finally {
      this.submitting = false
    }
  }

  getCardVerificationPayload({
    card,
    address,
    cardVerification,
  }: {
    card: SelectedPaymentMethodCreditCard
    address?: Address
    cardVerification?: CardVerificationConfig
  }) {
    return {
      card,
      amount: this.payWithCard + this.transactionFees,
      subTotal: this.subTotal,
      address: address ?? this.address,
      multipleSources: this.multipleSourcesSelected,
      type: this.cardVerificationType,
      modalsConfig: cardVerification?.modalsConfig,
      payeeId: undefined as string | undefined,
    }
  }

  async preview() {
    if (!this.previewURL) {
      throw new Error(
        `Property "previewURL" is not specified for the class ${this.constructor.name}`
      )
    }

    const payload = this.getPayload()

    try {
      const { data } = await api.post<ApiResponseData<PaymentOrderList>>(
        this.previewURL,
        payload
      )

      return data
    } catch (error: any) {
      console.error((error.data && error.data.message) || error)
      throw error
    }
  }

  showBankAccountWarning() {
    return new Promise<void>((resolve, reject) => {
      if (!this.payWithBankAccount) {
        resolve()

        return
      }

      const modalController = ui.mobile ? bottomSheet : modal

      modalController.show('bank-account-warning', {
        props: {
          total: this.total,
          onConfirm: resolve,
          onCancel: () => reject(new Error(ERROR_PAYMENT_CANCELLED)),
        },
        on: {
          hide: () => reject(new Error(ERROR_PAYMENT_CANCELLED)),
        },
      })
    })
  }

  async pay() {
    if (!this.checkoutURL) {
      throw new Error(
        `Property "checkoutURL" is not specified for the class ${this.constructor.name}`
      )
    }

    const { isBillPaymentsTemplate } = useProvider()

    const payload = this.getPayload()

    try {
      if (!isBillPaymentsTemplate.value) {
        await this.showBankAccountWarning()
      }

      const { data } = await api.post<ApiResponseData<Record<string, any>>>(
        this.checkoutURL,
        payload
      )

      this.onPayFinished(data)

      return data
    } catch (error: any) {
      if (error.message !== ERROR_PAYMENT_CANCELLED) {
        console.error(error.data?.errors?.[0] || error.data?.message || error)
      }

      throw error.data ?? error
    }
  }

  finishPayment(order = undefined) {
    this.confirmation.order = order
    this.reset()
  }

  abstract onPayFinished(_: unknown): void

  async updatePromotionCodes() {
    const coupons = []

    for (const coupon of this.selectedCoupons) {
      const { promotionCode } = coupon

      try {
        const { data } = await api.get<ApiResponseData<PromotionCodeRawData>>(
          `/v3/current-order/promotion-code/${promotionCode}`
        )

        if (data.promotionCode && data.amount > 0) {
          coupons.push({ ...data, type: PaymentMethodType.couponCode })
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.selectedCoupons = coupons
      }
    }
  }

  async verifyPromotionCodeCurrentOrder(promotionCode: string) {
    this.isCouponVerifying = true

    try {
      const { data } = await api.get<any>(
        `/v3/current-order/promotion-code/${promotionCode}`
      )

      if (data.promotionCode && data.amount > 0) {
        this.selectedCoupons = [
          ...this.selectedCoupons,
          { ...data, type: PaymentMethodType.couponCode },
        ]

        return [null, data]
      } else {
        const error = { message: 'Promotion code validation failed' }

        return [error]
      }
    } catch (error) {
      console.error(error)
      return [error]
    } finally {
      this.isCouponVerifying = false
    }
  }

  removeCartPromotionCode(promotionCode: string) {
    this.selectedCoupons = this.selectedCoupons.filter((item) => {
      return item.promotionCode !== promotionCode
    })
  }

  updateAmountsOnCoupons(newCoupons: any) {
    const newPayWithCoupons = newCoupons.reduce(
      (sum: number, i: any) => sum + (Number(i.amount) ?? 0),
      0
    )
    const diff = newPayWithCoupons - this.payWithCoupons

    if (diff > 0 && diff === this.subTotalWithProgramFees) {
      this.resetPaymentMethods()

      return
    }

    let methodsTypes = Object.entries(PAYMENT_METHODS_SORT_ORDER)
      .sort((a, b) => b[1] - a[1])
      .map((i) => i[0])

    if (diff > 0) {
      methodsTypes = methodsTypes.filter(
        (i) => Boolean(i) && i !== PaymentMethodType.couponCode
      )
    } else if (diff < 0) {
      methodsTypes = methodsTypes.filter(
        (i: any) =>
          Boolean(i) &&
          [
            PaymentMethodType.creditCard,
            PaymentMethodType.bankAccount,
          ].includes(i)
      )
    }

    let remainingAmount = diff

    for (const methodType of methodsTypes) {
      let method

      switch (methodType) {
        case PaymentMethodType.bankAccount:
          method = this.selectedBankAccount
          break
        case PaymentMethodType.creditCard:
          method = this.selectedCard
          break
        case PaymentMethodType.eWallet:
          method = this.selectedEwallet
          break
        case PaymentMethodType.points:
          method = this.selectedPoints
          break
      }

      if (method) {
        const { calculatedAmount, type } = method

        if (calculatedAmount > remainingAmount || remainingAmount < 0) {
          if (type === PaymentMethodType.points) {
            delete method.usePoints
          }

          method.calculatedAmount = roundFigure(
            calculatedAmount - remainingAmount
          )
          remainingAmount = 0
        } else {
          remainingAmount -= calculatedAmount
          method = null
        }
      }

      if (remainingAmount === 0) {
        break
      }
    }
  }

  getOrder(_: string) {
    throw new Error(
      `Method "getOrder" is not implemented for the class ${this.constructor.name}`
    )
  }
}

export default Checkout

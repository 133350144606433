import emitter from '/~/core/emitter'
import { roundFigure } from '/~/utils/format/numeric'
import { useCart } from '/~/composables/cart'
import OrderCheckout from '/~/composables/checkout/core/Checkout/OrderCheckout/OrderCheckout'
import { usePoints } from '/~/composables/points'

const {
  cartTotal,
  cartItemsFiltered,
  cartHasPhysical,
  cartHasEstore,
  cartHasDelivery,
  cartShippingFee,
  clearCart,
  resetCardOrderAddresses,
} = useCart()
const { calculatePointsEarnedForPurchase } = usePoints()

export class PurchaseCheckout extends OrderCheckout {
  constructor() {
    super()

    setTimeout(() => {
      this.amount = cartTotal.value
    })

    emitter.on('cart:updated', (total) => {
      this.resetCouponsAndPaymentMethods()
      this.amount = total
    })
  }

  get checkoutItemsCount() {
    return cartItemsFiltered.value?.length ?? 0
  }

  get isAddressSelectionAllowed() {
    return cartHasPhysical.value
  }

  get pointsEarned() {
    return calculatePointsEarnedForPurchase(cartItemsFiltered.value)
  }

  get shippingFees() {
    return cartShippingFee.value
  }

  get cartAmount() {
    return (
      roundFigure(cartTotal.value) + this.transactionFees - this.payWithCoupons
    )
  }

  get hasPhysicalItems() {
    return cartHasPhysical.value
  }

  get hasEstoreItems() {
    return cartHasEstore.value
  }

  get hasDeliverableItems() {
    return cartHasDelivery.value
  }

  initPayment(payment: any) {
    super.initPayment(payment)

    this.amount = this.cartAmount
  }

  clearItems() {
    clearCart()
    resetCardOrderAddresses()
  }

  onPayFinished(_: unknown) {
    setTimeout(() => {
      emitter.emit('purchases-updated')
      emitter.emit('savings-updated')
      this.clearItems()
    }, 5000)
  }

  reset() {
    super.reset()

    this.amount = this.cartAmount
  }

  get checkoutURL() {
    return '/v3/purchase-orders/checkout'
  }
}

export default PurchaseCheckout

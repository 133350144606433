import { computed, reactive, ref, watch } from 'vue'
import {
  FlowType,
  getFlowTypeFromOrderNumber,
} from '/~/composables/checkout/checkout-types'
import BatchOrderCheckout from '/~/composables/checkout/core/Checkout/BatchOrderCheckout'
import PurchaseCheckout from '/~/composables/checkout/core/Checkout/OrderCheckout/PurchaseCheckout'
import QuickBuyCheckout from '/~/composables/checkout/core/Checkout/OrderCheckout/QuickBuyCheckout'
import PayeeCheckout from '/~/composables/checkout/core/Checkout/PaymentCheckout/PayeeCheckout'
import StatementCheckout from '/~/composables/checkout/core/Checkout/PaymentCheckout/StatementCheckout'
import { useLogger } from '/~/composables/logger'

const logger = useLogger()

const currentFlowType = ref<FlowType>(FlowType.purchase)

watch(
  currentFlowType,
  (value) => logger.debug(`[checkout]: flow type is ${value}`),
  { immediate: true }
)

type CheckoutRoute = {
  meta?: {
    flowType?: FlowType
    quickBuy?: boolean
  }
  params?: { orderId?: string }
}

function getFlowTypeFromRoute({ meta, params }: CheckoutRoute) {
  const { flowType, quickBuy = false } = meta ?? {}
  const { orderId } = params ?? {}

  const orderFlowType = orderId
    ? getFlowTypeFromOrderNumber(orderId)
    : undefined

  if (orderFlowType) return orderFlowType
  else if (flowType) return flowType
  else if (quickBuy) return FlowType.quickBuy
  else return FlowType.purchase
}

const isPaymentCurrentFlowType = computed(
  () => currentFlowType.value === FlowType.payment
)
const isPurchaseCurrentFlowType = computed(
  () => currentFlowType.value === FlowType.purchase
)
const isQuickBuyCurrentFlowType = computed(
  () => currentFlowType.value === FlowType.quickBuy
)
const isStatementCurrentFlowType = computed(
  () => currentFlowType.value === FlowType.statement
)
const isBatchOrderCurrentFlowType = computed(() =>
  [FlowType.batch, FlowType.payroll].includes(currentFlowType.value)
)

const isPurchaseGroupCurrentFlowType = computed(
  () =>
    currentFlowType.value === FlowType.purchase ||
    currentFlowType.value === FlowType.quickBuy
)

const payment = computed(() => {
  switch (currentFlowType.value) {
    case FlowType.payment:
      return reactive(new PayeeCheckout())
    case FlowType.statement:
      return reactive(new StatementCheckout())
    case FlowType.payroll:
    case FlowType.batch:
      return reactive(new BatchOrderCheckout())
    case FlowType.quickBuy:
      return reactive(new QuickBuyCheckout())
    case FlowType.purchase:
    default:
      return reactive(new PurchaseCheckout())
  }
})

export function useCheckoutReactive() {
  return {
    payment,
    currentFlowType,
    isPaymentCurrentFlowType,
    isPurchaseCurrentFlowType,
    isQuickBuyCurrentFlowType,
    isStatementCurrentFlowType,
    isBatchOrderCurrentFlowType,
    isPurchaseGroupCurrentFlowType,
    getFlowTypeFromRoute,
  }
}

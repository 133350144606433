import { ref } from 'vue'
import { BankAccountSchema, AccountPresentation } from '../../bank-account'

const initiateValues = {
  bsb: '',
  accountNumber: '',
  accountName: '',
  termsAndConditions: false,
}

const form = ref(initiateValues)

const bankAccountSchema: BankAccountSchema = {
  fields: [
    {
      type: 'string',
      key: 'bsb',
      label: 'Routing Number',
      validation: {
        rules: 'required|usa_bsb',
      },
      placeholder: '000000000',
      mask: {
        mask: '000000000',
        lazy: true,
      },
      unmaskedModelValue: true,
      analyticsLabel: 'BSB',
    },
    {
      type: 'string',
      key: 'accountNumber',
      label: 'Account Number',
      validation: {
        rules: 'required|numeric|min:4|max:17',
      },
      analyticsLabel: 'Account number',
    },
    {
      type: 'string',
      key: 'accountName',
      label: 'Account Name',
      validation: {
        rules: 'required|min:2|max:100',
      },
      analyticsLabel: 'Account name',
    },
  ],
  types: {},
}

const accountPresentation: AccountPresentation = {
  accountNumber: 'accountNumber',
  name: 'accountName',
  bsb: 'bsb',
}

export default {
  form,
  initiateValues,
  bankAccountSchema,
  accountPresentation,
}

import Decimal from 'decimal.js'
import { roundFigure } from '/~/utils/format'

export function formatPoints(
  value: number | bigint | Intl.StringNumericLiteral
) {
  const formatter = new Intl.NumberFormat('en-AU', {
    maximumFractionDigits: 0,
    roundingMode: 'floor',
  })

  return formatter.format(value || 0)
}

export function calculatePurchasePointsEarned(
  value: number,
  rebatePercentage: number,
  pointsEarnRate: number
) {
  const rebateValue = new Decimal(value).div(100).mul(rebatePercentage)

  return pointsEarnRate !== 0
    ? roundFigure(rebateValue.div(pointsEarnRate).toNumber())
    : 0
}

export function calculatePaymentPointsEarned(
  value: number,
  pointsEarnRate: number
) {
  return roundFigure(new Decimal(value).mul(pointsEarnRate).toNumber())
}

export default {
  // Registration
  'account-details-info': () =>
    import('./registration/account-details-info.mdl.vue'),
  // Profile
  'edit-profile': () => import('./profile/edit-profile.mdl.vue'),
  'profile-password-confirmation': () =>
    import('./profile/password-confirmation.mdl.vue'),
  'new-address': () => import('./new-address.mdl.vue'),

  // How it works, policy, terms and FAQ's
  faq: () => import('./faq/faq.mdl.vue'),
  'how-it-works': () => import('./how-it-works/how-it-works.mdl.vue'),
  'how-it-works-and-faq': () =>
    import('./how-it-works/how-it-works-and-faq.mdl.vue'),
  'privacy-policy': () => import('./privacy-policy.mdl.vue'),
  'terms-conditions': () =>
    import('./terms-conditions/terms-conditions.mdl.vue'),

  // Filters
  'ew-categories': () => import('./ewallet/ew-categories.mdl.vue'),

  // Recroom
  'rec-media-gallery': () => import('./gallery/rec-media-gallery.mdl.vue'),
  'earned-points': () => import('./earned-points/earned-points.mdl.vue'),

  connect: () => import('./connect/connect.mdl.vue'),

  // Pipelines
  'eh-authority-form': () => import('./pipelines/eh-authority-form.mdl.vue'),
  'pa-corporate-travel-insurance-form': () =>
    import('./pipelines/pa-corporate-travel-insurance-form.mdl.vue'),
  'pa-critical-illness-insurance-form': () =>
    import('./pipelines/pa-critical-illness-insurance-form.mdl.vue'),
  'pa-indemnity-form': () => import('./pipelines/pa-indemnity-form.mdl.vue'),
  'pa-insurance-comparison-form': () =>
    import('./pipelines/pa-insurance-comparison-form.mdl.vue'),
  'pa-life-insurance-form': () =>
    import('./pipelines/pa-life-insurance-form.mdl.vue'),
  'pa-prestige-home-content-insurance-form': () =>
    import('./pipelines/pa-prestige-home-content-insurance-form.mdl.vue'),
  'pa-public-liability-insurance-form': () =>
    import('./pipelines/pa-public-liability-insurance-form.mdl.vue'),

  // Checkout
  'checkout-preview': () =>
    import('./checkout-preview/checkout-preview.mdl.vue'),

  // Payees
  'delete-payee': () =>
    import('/~/templates/bill-payments/modals/payees/delete-payee.mdl.vue'),
  'edit-payee': () =>
    import('/~/templates/bill-payments/modals/payees/edit-payee.mdl.vue'),

  // Payments
  'payments-split-modal': () =>
    import('./payments/split/payments-split-modal.vue'),

  // Payment methods
  'nab-verification': () =>
    import(
      './payment-methods/nab-verification/modals/nab-verification.mdl.vue'
    ),
  'nab-verification-cancel': () =>
    import(
      './payment-methods/nab-verification/modals/nab-verification-cancel.mdl.vue'
    ),
  'bank-account-warning': () =>
    import('./payment-methods/info/bank-account-warning.mdl.vue'),
  '3ds-verification-issue': () =>
    import(
      './payment-methods/3ds-verification-issue/3ds-verification-issue.vue'
    ),

  // Purchases
  'purchase-external': () => import('./purchase/purchase-external.mdl.vue'),

  // Global
  'side-menu-modal': () => import('./side-menu-modal.mdl.vue'),

  // Confirm
  confirm: () => import('./confirm/confirm.mdl.vue'),
  'confirm-v2': () => import('./confirm/confirm-v2.mdl.vue'),
  'confirm-v3': () => import('./confirm/confirm-v3.mdl.vue'),

  // Verification center
  'verification-warning': () =>
    import('/~/extensions/otp/components/verification-warning.mdl.vue'),

  //CMS
  'cms-modal': () => import('/~/modals/cms/cms-modal/cms-modal.mdl.vue'),

  //Pay-Id
  'complete-payment-payid': () =>
    import('/~/modals/pay-id/complete-payment-payid.vue'),
  'about-payid': () => import('/~/modals/pay-id/about-payid.vue'),
}

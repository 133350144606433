import {
  PaymentMethodBankAccount,
  PaymentMethodCreditCard,
  PaymentMethodEwallet,
  PaymentMethodPoints,
  PaymentMethodPayId,
} from '/~/types/api/payment-methods'

export enum FlowType {
  payment = 'PaymentOrder',
  purchase = 'Order',
  quickBuy = 'QuickBuyOrder',
  statement = 'StatementOrder',
  batch = 'BatchOrder',
  payroll = 'PayrollOrder',

  pointsProgramOrder = 'PointsProgramOrder',
  qrPayOrder = 'QRPayOrder',
  topUpOrder = 'TopUpOrder',
  withdrawOrder = 'WithdrawOrder',
}

type PaymentMethodWithCalculatedAmount = {
  calculatedAmount: number
}

export type SelectedPaymentMethod =
  | SelectedPaymentMethodEwallet
  | SelectedPaymentMethodPoints
  | SelectedPaymentMethodCreditCard
  | SelectedPaymentMethodBankAccount
  | SelectedPaymentMethodPayId

export type SelectedPaymentMethodEwallet = PaymentMethodEwallet &
  PaymentMethodWithCalculatedAmount & {
    fee?: number
  }

export type SelectedPaymentMethodPayId = PaymentMethodPayId &
  PaymentMethodWithCalculatedAmount

export type SelectedPaymentMethodPoints = PaymentMethodPoints &
  PaymentMethodWithCalculatedAmount & {
    usePoints?: number
    fee?: number
  }

export type SelectedPaymentMethodCreditCard = PaymentMethodCreditCard &
  PaymentMethodWithCalculatedAmount & {
    fee?: number
    percentageFeeRate?: number
  }

export type SelectedPaymentMethodBankAccount = PaymentMethodBankAccount &
  PaymentMethodWithCalculatedAmount & {
    fee?: number
    percentageFeeRate?: number
  }

export function isSelectedMethod<
  B extends object,
  S extends PaymentMethodWithCalculatedAmount
>(item: B | S | undefined | null): item is S {
  return item ? 'calculatedAmount' in item : false
}

export function getFlowTypeKey(value: string) {
  const index = Object.values(FlowType).indexOf(value as unknown as FlowType)

  const key = Object.keys(FlowType)[index]

  return key
}

export function getOrderType(value: FlowType): FlowType {
  if (value === FlowType.quickBuy) {
    return FlowType.purchase
  }

  return value
}

export function getFlowTypeFromOrderNumber(
  orderNumber: string
): FlowType | undefined {
  switch (orderNumber[0]) {
    case 'E':
      return FlowType.payroll
    case 'P':
      return FlowType.payment
    case 'S':
      return FlowType.statement
    case 'B':
      return FlowType.batch
    case 'G':
      return FlowType.purchase
    case 'A':
      return FlowType.pointsProgramOrder
    default:
      console.warn(`Unknown order type: ${orderNumber}`)
  }
}

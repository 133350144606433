import Vue, { computed, reactive, readonly, ref } from 'vue'
import {
  ApiResponseData,
  BatchOrder as BatchOrderRawData,
  BatchOrderType,
} from '/~/types/api'
import api from '/~/core/api'
import modal from '/~/core/mdl'
import { BatchOrder } from '/~/composables/batch-order'
import { usePoints } from '/~/composables/points'
import router from '/~/router'
const BANK_FILE_BATCH_ORDER_TYPE = 'business'
const PAYROLL_BATCH_ORDER_TYPE = 'payroll'

const { calculatePointsEarnedForPayment } = usePoints()

const batchOrderFlowType = ref<BatchOrderType>('business')

const batchOrder = reactive(new BatchOrder())
const batchOrderPointsToEarn = computed(() =>
  calculatePointsEarnedForPayment(batchOrder.subtotal)
)
const isBatchOrderLoading = ref(false)

const isPayeesSyncedBatchOrder = computed(() =>
  ['payees_synced'].includes(batchOrder.status)
)

function setBatchOrderLoading(isLoading: boolean) {
  isBatchOrderLoading.value = isLoading
}

function setBatchOrder(
  batchOrderData: BatchOrderRawData | undefined = undefined
) {
  Object.assign(batchOrder, new BatchOrder(batchOrderData))
}

async function syncBatchOrderPayees() {
  setBatchOrderLoading(true)

  try {
    await api.post(
      `/v3/batch-orders/${batchOrder.number}/transactions/sync-payees/`
    )
  } catch (error) {
    console.error(error)
  }
}

async function fetchBatchOrder(
  batchOrderNumber: string,
  options: {
    checkType?: boolean
    useLoading: boolean
  } = {
    useLoading: true,
  }
) {
  if (options.useLoading) {
    setBatchOrderLoading(true)
  }

  const response = await api.get<ApiResponseData<BatchOrderRawData>>(
    `/v3/batch-orders/${batchOrderNumber}`
  )

  setBatchOrder(response.data)

  if (options.useLoading) {
    setBatchOrderLoading(false)
  }

  return response.data
}

async function cancelBatchOrder() {
  setBatchOrderLoading(true)

  await api.put(`/v3/batch-orders/${batchOrder.number}`, {
    status: 'cancelled',
  })

  setBatchOrder()
  setBatchOrderLoading(false)
}

async function cancelOrder() {
  try {
    const batchOrderType = batchOrder.type

    await cancelBatchOrder()
    if (batchOrderType === PAYROLL_BATCH_ORDER_TYPE) {
      router.push({
        name: 'payroll-upload',
      })
    } else if (batchOrderType === BANK_FILE_BATCH_ORDER_TYPE) {
      router.push({
        name: 'bank-file-upload',
      })
    }
  } catch (error) {
    Vue.notify({
      type: 'error',
      text: 'There was an error cancelling an order. Please try again.',
    })
  }
}

function onCancelBatchOrder(modalProps = { onConfirm: async () => {} }) {
  modal.show('confirm-v3', {
    props: {
      title: 'Cancel batch payment',
      description: `Are you sure you want to cancel?

        This will cancel the entire batch payment. Any payees already added will be available for any future payments, and any payees submitted will be reviewed.`,
      width: 'xs',
      showCancel: false,
      confirmLabel: 'Cancel batch payment',
      onConfirm: async () => {
        await cancelOrder()
        await modalProps.onConfirm?.()
      },
    },
  })
}

function onRemoveBatchFile(modalProps = { onConfirm: async () => {} }) {
  modal.show('confirm-v3', {
    props: {
      title: 'Remove file',
      description: 'Are you sure you want to remove the file?',
      width: 'xs',
      onConfirm: async () => {
        await cancelOrder()
        await modalProps.onConfirm?.()
      },
    },
  })
}

async function createBatchOrder(payload: { fileUploadId: string }) {
  setBatchOrderLoading(true)

  try {
    const response = await api.post<ApiResponseData<BatchOrderRawData>>(
      '/v3/batch-orders',
      {
        ...payload,
      }
    )

    if (response) {
      setBatchOrder(response.data)
    }
  } finally {
    setBatchOrderLoading(false)
  }
}

export function useBatchOrder() {
  return {
    BANK_FILE_BATCH_ORDER_TYPE,
    PAYROLL_BATCH_ORDER_TYPE,
    batchOrderFlowType,
    batchOrder: readonly(batchOrder),
    batchOrderPointsToEarn,
    isBatchOrderLoading: readonly(isBatchOrderLoading),
    setBatchOrderLoading,
    setBatchOrder,
    fetchBatchOrder,
    cancelBatchOrder,
    cancelOrder,
    onCancelBatchOrder,
    onRemoveBatchFile,
    syncBatchOrderPayees,
    isPayeesSyncedBatchOrder,
    createBatchOrder,
  }
}

import { reactive } from 'vue'
import modal from '/~/core/mdl'
import { BatchOrder } from './BatchOrder'
import { BatchOrderLoader, BatchOrderLoaderParams } from './BatchOrderLoader'

export class BatchOrderStatusHandler {
  batchOrderLoader: BatchOrderLoader
  batchOrder: BatchOrder = new BatchOrder()
  previousStatus = ''
  goToPage: (status: string) => void
  syncBatchOrderPayees: () => void
  goToCheckout: () => void
  isCheckingLoop = false
  readyToPay = false
  isPayeesSyncing = false

  constructor(
    params: BatchOrderLoaderParams,
    goToPage: (status: string) => void,
    goToCheckout: () => void,
    syncBatchOrderPayees: () => void
  ) {
    this.batchOrderLoader = reactive(new BatchOrderLoader(params))
    this.batchOrder = reactive(new BatchOrder())
    this.goToPage = goToPage
    this.goToCheckout = goToCheckout
    this.syncBatchOrderPayees = syncBatchOrderPayees
  }

  setBatchOrder(batchOrderData: BatchOrder) {
    Object.assign(this.batchOrder, new BatchOrder(batchOrderData))
  }

  setReadyToPay(ready = true) {
    this.readyToPay = ready
  }

  resetBatchOrder() {
    Object.assign(this.batchOrder, new BatchOrder())
  }

  handle(status: string) {
    if (this.previousStatus !== status) {
      this.previousStatus = status
      this.goToPage(status)
    }
  }

  async check() {
    const response = await this.batchOrderLoader.load()
    const { items = [] } = response ?? {}
    const firstItem = items[0]

    if (!firstItem) {
      this.isCheckingLoop = false
      this.resetBatchOrder()
      modal.hide('batch-payment-validating-file')
      console.error('No items in batch order')
      return
    }

    this.setBatchOrder(firstItem)

    if (firstItem && !modal.hasOpenedModals) {
      modal.show('batch-payment-validating-file')
    }

    if (this.batchOrder.isStatusBatchOrderPending) {
      this.isCheckingLoop = true
    } else if (this.batchOrder.isStatusFileProcessed) {
      if (!this.isPayeesSyncing) {
        this.syncBatchOrderPayees()
        this.isPayeesSyncing = true
      }
      this.isCheckingLoop = true
    } else if (this.readyToPay && this.batchOrder.isStatusPayeesSynced) {
      this.goToCheckout()
      this.isCheckingLoop = false
      this.readyToPay = false
      this.isPayeesSyncing = false
      modal.hide('batch-payment-validating-file')
    } else {
      this.isCheckingLoop = false
      this.readyToPay = false
      this.isPayeesSyncing = false
      modal.hide('batch-payment-validating-file')
      this.handle(this.batchOrder.status)
    }
  }

  startCheckingLoop() {
    this.previousStatus = this.batchOrder.status
    this.isCheckingLoop = true
    this.checkingLoop()
  }

  async checkingLoop() {
    if (this.isCheckingLoop) {
      await this.check()
      setTimeout(() => {
        this.checkingLoop()
      }, 15000)
    }
  }
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import emitter from '/~/core/emitter'
import { useAction } from '/~/composables/base/use-action'
import { useCms } from '/~/composables/cms/use-cms'
import { getConfigNameByRoute } from '/~/configurator/utils'
import authRoutes from '/~/router/auth-routes'
import globalRoutes from '/~/router/global-routes'
import { registerRouterExtensions } from '/~/router/router-extensions'
import {
  handleBlacklistedCms,
  handleQRCodeRedirection,
  handleUserAuthorization,
  setDocumentTitle,
  setDynamicHeroHeight,
  handleExtensionTasks,
  handleCheckout,
  handleCmsModals,
  handleThirdPartyUserAgreement,
} from '/~/router/router-handlers'
import {
  scrollBehavior,
  handleScrollPositionSaving,
} from '/~/router/scroll-behavior'

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  routes: [],
  scrollBehavior,
})

registerRouterExtensions(router)
for (const route of [...authRoutes, ...globalRoutes]) {
  router.addRoute(route)
}

router.afterEach((to, from) => {
  const { pages } = useCms()

  router.historyLength = (router.historyLength || 0) + 1

  emitter.emit('router:after-each', {
    name: router.currentRoute.name,
    params: router.currentRoute.params,
    query: router.currentRoute.query,
    path: router.currentRoute.path,
  })

  const page = getConfigNameByRoute(router.currentRoute)

  const content = pages.value[page]

  if (to.path !== from.path && content?.settings?.pageAction) {
    const { handleAction } = useAction(content.settings.pageAction)

    handleAction()
  }
})

router.beforeEach(async (toRoute, fromRoute, next) => {
  handleCheckout(toRoute)
  handleBlacklistedCms(toRoute, next) // redirect home if blacklisted
  handleScrollPositionSaving(fromRoute)
  setDocumentTitle(toRoute)
  handleQRCodeRedirection(toRoute, next)
  handleCmsModals(toRoute, fromRoute)

  if (!handleUserAuthorization(toRoute, next)) return
  if (!(await handleExtensionTasks(toRoute, next))) return

  if (!handleThirdPartyUserAgreement(toRoute, next)) return

  next()

  setDynamicHeroHeight()
})

export default router

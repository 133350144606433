import { loadStripe } from '@stripe/stripe-js/pure'
import { KycService } from '/~/extensions/otp/composables/core/Kyc/Kyc'

export class StripeVerificationService extends KycService {
  isValidSessionResponse(data: Kyc.PostIdentityVerifications) {
    return Boolean(data.clientSecret)
  }

  async startVerification() {
    const session = this.verificationSession
    const stripe = await loadStripe(eonx.keys.stripe)

    if (!stripe) {
      throw new Error('Stripe SDK not loaded')
    }

    const clientSecret =
      session && 'clientSecret' in session ? session.clientSecret : null

    if (!clientSecret) {
      throw new Error('Client secret missing')
    }

    const { verificationSession, error } = await stripe.verifyIdentity(
      clientSecret
    )

    if (error) {
      console.error(error)

      throw new Error(error.message)
    }

    return verificationSession
  }
}

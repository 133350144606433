import { reactive } from 'vue'
import { BronVerificationService } from '/~/extensions/otp/composables/core/Kyc/BronKyc'
import { StripeVerificationService } from '/~/extensions/otp/composables/core/Kyc/StripeKyc'
import { useUser } from '/~/composables/user'

export class KycServiceFactory {
  static create(serviceType: Kyc.ServiceType) {
    const { user } = useUser()

    switch (serviceType) {
      case 'bronId':
        return reactive(
          new BronVerificationService({
            expirationDay: 31,
            userId: user.value.externalId,
          })
        )

      default:
        return reactive(
          new StripeVerificationService({
            expirationDay: 1,
            userId: user.value.externalId,
          })
        )
    }
  }
}

import VueRouter from 'vue-router'
import emitter from '/~/core/emitter'
import { lazyRoute, configure } from '/~/utils/lazy'
import { useAction } from '/~/composables/base/use-action'
import { useCms } from '/~/composables/cms/use-cms'
import exchange from '/~/configurator/exchange'
import { getConfigNameByRoute } from '/~/configurator/utils'
import Loading from '/~/views/loading.vue'
import TimeoutModal from '/~/views/timeout/timeout.mdl.vue'
import Timeout from '/~/views/timeout/timeout.vue'

const { isNavigationFailure, NavigationFailureType } = VueRouter

// https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
// this method actually works
// TODO: add type to router
function patchRouterMethod(router: any, methodName: string) {
  router['old' + methodName] = router[methodName]
  // TODO: add type to location
  router[methodName] = async function (location: any) {
    if (location === undefined) {
      return
    }

    const { query = {}, hash } = location
    const { query: currentQuery = {} } = this.currentRoute

    if (hash !== undefined) {
      location.query = {
        ...currentQuery,
        ...query,
      }
    }

    return router['old' + methodName](location).catch((error: Error) => {
      if (error.name === 'NavigationDuplicated') {
        return this.currentRoute
      } else if (isNavigationFailure(error, NavigationFailureType.redirected)) {
        // NOTE: auth redirect
        // https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
        return this.currentRoute
      }
      throw error
    })
  }
}

// TODO: add type to router
export const registerRouterExtensions = (router: any) => {
  patchRouterMethod(router, 'push')
  patchRouterMethod(router, 'replace')

  // Store the routes for later use or external access
  window.routes = []

  // Notify a parent frame when the routes have been loaded
  exchange.sendToParent('routes-loaded', window.routes)

  // Store the original addRoute method
  const originalAddRoute = router.addRoute

  // Override the addRoute method with custom behavior
  // TODO: add concrete type to route and parentRoute
  router.addRoute = function (route: any, parentRoute: any) {
    const _route = lazyRoute(route)

    // Add the route to the stored routes
    window.routes.push(_route)

    // Notify the parent frame that a new route has been added
    exchange.sendToParent('routes-loaded', window.routes)

    // Call the original addRoute method with the given arguments
    if (parentRoute) {
      return originalAddRoute.call(router, parentRoute, _route)
    } else {
      return originalAddRoute.call(router, _route)
    }
  }

  const preloadAction = async function () {
    /*
     * Get page configuration if it needed
     */
    const route = router.currentRoute
    const page = getConfigNameByRoute(route)
    const { getPage, pages } = useCms()

    if (page) {
      const isLoaded = Boolean(pages.value[page])

      if (!isLoaded) {
        await getPage(page)
        const content = pages.value[page]

        if (content?.settings?.pageAction) {
          const { handleAction } = useAction(content.settings.pageAction)

          handleAction()
        }
      }
    } else if (route.name) {
      emitter.emit('page-empty', route.name)
    }
  }

  // NOTE: configure lazy
  configure({
    errorComponent: Timeout,
    errorModalComponent: TimeoutModal,
    loadingComponent: Loading,
    preloadAction,
  })
}

import Vue from 'vue'
import {
  ApiResponseData,
  BatchOrder as BatchOrderRawData,
  PaymentOrderList,
  ProgramFees,
} from '/~/types/api'
import api from '/~/core/api'
import emitter from '/~/core/emitter'
import Address from '/~/composables/addresses/core/Address'
import { useBatchOrder } from '/~/composables/batch-order'
import { FlowType } from '/~/composables/checkout/checkout-types'
import Checkout from '/~/composables/checkout/core/Checkout'
import { Order } from '/~/composables/order'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'

const { burnPointsRateBatchOrder, calculatePointsEarnedForPayment } =
  usePoints()
const { isMethodAvailable } = usePaymentMethods()

const { BANK_FILE_BATCH_ORDER_TYPE, batchOrder, setBatchOrder } =
  useBatchOrder()

export class BatchOrderCheckout extends Checkout {
  flowType = FlowType.batch
  orderType = FlowType.batch
  cardVerificationType = 'payees' as const
  batchOrderType = BANK_FILE_BATCH_ORDER_TYPE
  reference: string | null = ''
  getOrderURL = '/v3/batch-orders'
  isSchedulingAllowed = false

  constructor() {
    super()

    emitter.on('addresses:remove', (address: Address) => {
      if (this.address && address && this.address.id === address.id) {
        this.address = null
      }
    })
  }

  get burnPointsRate() {
    return burnPointsRateBatchOrder.value
  }

  get pointsEarned() {
    return calculatePointsEarnedForPayment(batchOrder.subtotal)
  }

  get checkoutURL() {
    return `/v3/batch-orders/${batchOrder.number}/checkout`
  }

  get transactionFeesURL() {
    return `/v3/payment-methods/fees/batch-orders/${batchOrder.number}`
  }

  get subFlowType() {
    return this.batchOrderType
  }

  get isReadyForPayment() {
    return Boolean(this.reference)
  }

  get canAddPaymentMethods() {
    return (
      isMethodAvailable(PaymentMethodType.creditCard, FlowType.payment) ||
      isMethodAvailable(PaymentMethodType.bankAccount, FlowType.payment)
    )
  }

  get termsShouldBeAccepted() {
    return Boolean(this.payWith.bankAccount)
  }

  get rootRoute() {
    return {
      name: 'bank-file-home',
    }
  }

  async preview() {
    return {} as PaymentOrderList
  }

  async initPayment(payment: any) {
    super.initPayment(payment)

    this.amount = batchOrder.subtotal
    this.reference = batchOrder.reference
    this.batchOrderType = batchOrder.flowType ?? BANK_FILE_BATCH_ORDER_TYPE

    await this.getProgramFees()
  }

  reset() {
    super.reset()

    this.reference = null
  }

  async getProgramFees() {
    const amount = this.subTotal

    if (amount === 0) {
      return
    }

    this.loadingProgramFees = true

    try {
      const { data } = await api.post<ApiResponseData<ProgramFees>>(
        '/v3/program-fees',
        { amount },
        { notify: false }
      )

      this.programFeesData = data
      this.programFees = Number(data?.totalFees ?? 0)
      this.programFeesPercentage = Number(data?.fees[0]?.percentage ?? 0)
    } catch (error: any) {
      Vue.notify({
        type: 'error',
        text: 'Something went wrong loading program fees',
      })

      console.error((error.data && error.data.message) || error)
    } finally {
      this.loadingProgramFees = false
    }
  }

  getPayload() {
    if (!this.address) {
      return
    }

    return {
      ...super.getPayload(),
      addressId: String(this.address.id),
      reference: this.reference,
    }
  }

  onPayFinished() {}

  async getOrder(orderNumber: string) {
    this.confirmation.loading = true

    try {
      const { data } = await api.get<ApiResponseData<BatchOrderRawData>>(
        `/v3/batch-orders/${orderNumber}`
      )

      const order = new Order(data)

      this.confirmation.order = order

      setBatchOrder(order)

      return order
    } catch (error: any) {
      console.error((error.data && error.data.message) || error)
      throw error
    } finally {
      this.confirmation.loading = false
    }
  }
}

export default BatchOrderCheckout

export enum ActivityItemType {
  Membership = 'membership',
  PointsProgramOrder = 'pointsprogramorder',
  PointsTransaction = 'pointstransaction',
  UserCashbackOrder = 'usercashbackorder',
  CashbackReconciliationFeed = 'cashbackreconciliationfeed',
  PaymentOrder = 'paymentorder',
  BatchOrder = 'batchorder',
  PurchaseOrder = 'purchaseorder',
  Refund = 'refund',
  StatementOrder = 'statementorder',
  UserCommunications = 'usercommunications',
  Campaign = 'campaign',
  TopupOrder = 'topuporder',
  QrcScan = 'qrcscan',
  QrPayOrder = 'qrpayorder',
  TopUpTransaction = 'topuptransaction',
  CashTransaction = 'cashtransaction',
  MigratedHistory = 'migratedhistory',
  WithdrawOrder = 'withdraworder',
  PointsTransferRequest = 'pointstransferrequest',
}

export enum ActivityItemMetadataStatus {
  Completed = 'completed',
  Approved = 'approved',
  Funded = 'funded',
  // purchase order
  Pending = 'pending',
  // batch order
  Processing = 'processing',
  RemittancePending = 'remittance_pending',
  AwaitingFundsPi = 'awaiting_funds_pi',
  Failed = 'failed',
  Declined = 'declined',
  Refunded = 'refunded',
  CompletedWithRemittanceFailures = 'completed_with_remittance_failures',
  DestinationFailed = 'destination_failed',
  Incomplete = 'incomplete',
  Cancelled = 'cancelled',
  Expired = 'expired',
  // Order statuses
  Scheduled = 'scheduled',
  PayeesSynced = 'payees_synced',
}

export enum ActivityItemPaymentMethodBrand {
  BankAccount = 'Bank Account',
  PayID = 'PayID',
  Points = 'Points',
  Visa = 'Visa',
}

export enum ReturnReasons {
  AmountMismatch = 'amount_mismatch',
  Expired = 'expired',
  Cancelled = 'cancelled',
  OrderNotFound = 'order_not_found',
  DuplicatePayment = 'duplicate_payment',
  PaymentFailed = 'payment_failed',
  Other = 'other',
}

export const ReturnReasonsMap: Record<ReturnReasons, string> = {
  [ReturnReasons.AmountMismatch]:
    'Your PayID payment could not be processed because the amount was incorrect. Your funds have been returned.',
  [ReturnReasons.Expired]:
    'Your PayID payment could not be processed because the PayID payment details had expired by the time the payment was made. Your funds have been returned.',
  [ReturnReasons.Cancelled]:
    'Your PayID payment could not be processed because the payment was cancelled. Your funds have been returned.', // No such message in design
  [ReturnReasons.OrderNotFound]:
    'Your PayID payment could not be processed because the details provided do not match any outstanding payments. Your funds have been returned.',
  [ReturnReasons.DuplicatePayment]:
    'Your PayID payment could not be processed because the funds have already been received. Your duplicate funds have been returned.',
  [ReturnReasons.PaymentFailed]:
    'We received your PayID payment but a processing error occurred. Your funds have been returned.',
  [ReturnReasons.Other]:
    'Your PayID payment could not be processed. Please contact our support team.',
}

export const ReturnReasonCompletedWithRemittanceFailures =
  'We received your PayID payment but one or more transfers to payee banks failed. See the payment details below.'
export const ReturnReasonCompletedWithRemittanceFailuresSummary =
  'Your funds will be returned.'

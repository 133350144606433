export function formatBusinessNumber(value: string) {
  const matches = String(value).match(/(\d{2})(\d{3})(\d{3})(\d{3}){1}/)

  if (matches) {
    matches.splice(0, 1)

    return matches.join(' ')
  } else {
    return value
  }
}

export function parseBsb(
  value: string | number,
  options = {
    showLabel: false,
  }
) {
  const result: Record<string, string | null> = { bsb: null }

  if (!value) {
    return result
  }

  const matches = String(value).match(/.{1,3}/g)

  if (matches) {
    result.bsb = (options.showLabel ? 'BSB: ' : '') + matches.join('-')
  }

  return result
}

export function formatBsb(value: string | number) {
  if (value) {
    const matches = String(value).match(/.{1,3}/g)

    if (matches) {
      return matches.join('-')
    }
  }

  return value
}

export const bsbLength = 6

export function postfixBsb(value: string) {
  return value.length === bsbLength ? value.slice(-3) : ''
}

import { RebateTotalByType } from '/~/types/api'
import { ensureNumber } from '/~/utils/format'

export function getCashbackRebate(rebateTotalByType: RebateTotalByType[]) {
  return ensureNumber(
    rebateTotalByType?.find(
      (rebate) => ensureNumber(rebate.cashback_rebate) > 0
    )?.cashback_rebate ?? 0
  )
}

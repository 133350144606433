import Vue from 'vue'
import { usePayees, NewPayee } from '/~/composables/payees'
import { BatchOrderPayee, PayeeRowStatuses } from './BatchOrderPayee'
import { BatchOrderPayeeList } from './BatchOrderPayeeList'

const BATCH_ORDER_PAYEES = 'batchOrderPayees'

export class BatchOrderPayeeListLocal extends BatchOrderPayeeList {
  localList: BatchOrderPayee[] = []

  get createdLocalPayees() {
    return this.localList.filter(
      (payee: BatchOrderPayee) => payee.status === PayeeRowStatuses.created
    )
  }

  initLocalList() {
    this.localList = JSON.parse(
      localStorage.getItem(BATCH_ORDER_PAYEES) || '[]'
    )
  }

  reset() {
    this.list = []
  }

  resetLocal() {
    localStorage.removeItem(BATCH_ORDER_PAYEES)
  }

  setItems(items: BatchOrderPayee[]) {
    this.reset()
    this.initLocalList()

    const localPayeeItems = JSON.parse(
      localStorage.getItem(BATCH_ORDER_PAYEES) || '[]'
    )

    items.forEach((item: BatchOrderPayee) => {
      const localPayeeIndex = localPayeeItems.findIndex(
        (payee: BatchOrderPayee) =>
          payee.hash === `${item.accountName}-${item.accountNumber}-${item.bsb}`
      )

      if (localPayeeIndex >= 0) {
        const localItem = localPayeeItems[localPayeeIndex]

        item = {
          ...localItem,
          status:
            localItem.status === PayeeRowStatuses.created
              ? PayeeRowStatuses.created
              : PayeeRowStatuses.empty,
        }
      } else {
        item = {
          ...item,
          hash: `${item.accountName}-${item.accountNumber}-${item.bsb}`,
          abn: '',
          entityName: '',
          status: PayeeRowStatuses.empty,
        }
      }
      this.list.push(item)
    })
  }

  updatePayeeLocal(
    item: BatchOrderPayee,
    params: { abn?: string; entityName?: string; status?: string }
  ) {
    this.list.forEach((payee: BatchOrderPayee) => {
      if (payee.hash === item.hash) {
        Object.entries(params).forEach(([key, value]) => {
          payee[key] = value
        })
        this.savePayeeLocal(payee)
      }
    })
    this.initLocalList()
  }

  savePayeeLocal(row: BatchOrderPayee) {
    const localPayeeItems = JSON.parse(
      localStorage.getItem(BATCH_ORDER_PAYEES) || '[]'
    )

    const localPayeeIndex = localPayeeItems.findIndex(
      (payee: BatchOrderPayee) => payee.hash === row.hash
    )

    if (localPayeeIndex < 0) {
      localPayeeItems.push(row)
    } else {
      localPayeeItems[localPayeeIndex] = row
    }

    localStorage.setItem(BATCH_ORDER_PAYEES, JSON.stringify(localPayeeItems))
  }

  clearAbn(row: BatchOrderPayee) {
    this.updatePayeeLocal(row, { abn: '', entityName: '' })
  }

  abnValidated(result: { $event: any; row: BatchOrderPayee }) {
    const { $event, row } = result

    this.updatePayeeLocal(row, {
      abn: $event.abn,
      entityName: $event.entityName,
    })
  }

  async addPayee(row: BatchOrderPayee) {
    const { createPayee } = usePayees()

    const newPayee: NewPayee = {
      accountName: String(row.accountName),
      name: String(row.entityName),
      accountNumber: String(row.accountNumber),
      bsb: String(row.bsb),
      abn: String(row.abn),
      type: 'bankAccount',
      category: 'business',
    }

    try {
      this.updatePayeeLocal(row, { status: PayeeRowStatuses.creating })
      await createPayee(newPayee)
      this.updatePayeeLocal(row, { status: PayeeRowStatuses.created })
    } catch (error: any) {
      Vue.notify({
        text: error.message || error.data?.message || 'Error creating payee',
        type: 'error',
        duration: 5000,
      })
      this.updatePayeeLocal(row, { status: PayeeRowStatuses.empty })
    }
  }
}

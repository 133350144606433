import modal from '/~/core/mdl'
import { KycService } from '/~/extensions/otp/composables/core/Kyc/Kyc'

export class BronVerificationService extends KycService {
  isValidSessionResponse(data: Kyc.PostIdentityVerifications) {
    return Boolean('bron_link' in data.metadata && data.metadata.bron_link)
  }

  async alert() {
    await new Promise((resolve, reject) => {
      modal.show('confirm-v3', {
        props: {
          title: 'Verify identity',
          description: `
            You are about to be redirected to BronID in a new tab to complete the next step in verifying your identity.
            <br/><br/><strong>Important Notes:</strong><br/><br/>
            <ul class="list-disc pl-4">
              <li>You have 31 days to complete your account verification.</li>
              <li>You are allowed a maximum of 5 verification attempts.</li>
            </ul>
          `,
          descriptionWhitespace: false,
          width: 'xs',
          onConfirm: resolve,
          onCancel: reject,
        },
        on: {
          hide: reject,
        },
      })
    })
  }

  async startVerification() {
    const session = this.verificationSession

    const bronIdLink =
      session?.metadata && 'bron_link' in session.metadata
        ? session.metadata.bron_link
        : null

    if (!bronIdLink) {
      throw new Error('Service link missing')
    }

    const bronIdWindow = window.open(bronIdLink, '_blank')

    bronIdWindow?.focus()

    if (
      !bronIdWindow ||
      bronIdWindow.closed ||
      typeof bronIdWindow.closed == 'undefined'
    ) {
      return new Promise((resolve) => {
        modal.show('confirm-v3', {
          props: {
            title: 'Popup blocked',
            description:
              'To proceed you may need to enable pop-ups in your browser',
            width: 'xs',
            showConfirm: false,
            cancelLabel: 'Close',
            onCancel: () => resolve(false),
          },
        })
      })
    }

    return true
  }
}

import emitter from '/~/core/emitter'
import { useBatchOrder } from '/~/composables/batch-order'
import { useCheckoutReactive } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useProvider } from '/~/composables/provider'
import router from '/~/router'
import { BatchOrderStatusHandler } from './core/BatchOrderStatusHandler'

const { setBatchOrder, syncBatchOrderPayees } = useBatchOrder()

const routes: Record<string, string> = {
  payees_synced: 'batch-payment-pending-payees',
  payee_pending: 'batch-payment-pending-payees',
  payee_not_found: 'batch-payment-new-payees-detected',
  payee_not_supported: 'batch-payment-unsupported-payees-detected',
}

const batchOrderAllStatuses = [
  'new',
  'file_processing',
  'file_processed',
  'payees_syncing',
  'payees_synced',
  'payee_not_found',
  'payee_not_supported',
  'payee_pending',
]

const batchOrderStatusHandler = new BatchOrderStatusHandler(
  {
    url: '/v3/batch-orders',
    statuses: batchOrderAllStatuses,
    type: 'business',
  },
  goToPageByStatus,
  goToCheckout,
  syncPayees
)

function syncPayees() {
  setBatchOrder(batchOrderStatusHandler.batchOrder)
  syncBatchOrderPayees()
}

function goToPageByStatus(status: string) {
  if (routes[status]) {
    setBatchOrder(batchOrderStatusHandler.batchOrder)
    router.push({
      name: routes[status],
    })
  } else {
    router.push({ name: 'bank-file-upload' })
  }
}

function goToCheckout() {
  const { isBillPaymentsV15Template } = useProvider()

  if (isBillPaymentsV15Template.value) {
    router.push({
      name: 'payments-make-batch',
      params: {
        orderId: batchOrderStatusHandler.batchOrder.number,
      },
    })
  } else {
    const { payment, currentFlowType } = useCheckoutReactive()

    currentFlowType.value = FlowType.batch
    payment.value.getOrder(batchOrderStatusHandler.batchOrder.number)
    payment.value.initPayment()

    router.push({ name: 'batch-bank-file-checkout' })
  }
}

emitter.on('notifications:batch-order', async (event) => {
  const { batchOrderId, batchOrderStatus, notification } = event

  if (document.visibilityState === 'hidden') {
    return
  }

  if (batchOrderStatusHandler.batchOrder.fileUploadId !== batchOrderId) {
    return
  }

  if (router?.history?.current.meta.flowType !== FlowType.batch) {
    return
  }

  if (batchOrderStatus === 'process_failed') {
    emitter.emit(
      'batch-order-creation-failed',
      'There was an error creating an order. Please try again.'
    )
  }

  if (batchOrderStatus === 'process_cancelled') {
    emitter.emit('batch-order-creation-cancelled', notification.title)
  }

  await batchOrderStatusHandler.check()
})

export function useBatchOrderStatusHandler() {
  return {
    batchOrderStatusHandler,
  }
}

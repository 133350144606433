import { computed, onMounted, watch } from 'vue'
import { ActivityItemPaymentMethodType } from '/~/types/api'
import emitter from '/~/core/emitter'
import { KycServiceFactory } from '/~/extensions/otp/composables/core/Kyc/KycFactory'
import { useExtensions } from '/~/composables/extensions'
import { useUser } from '/~/composables/user'

const moduleConfig = computed(() => {
  const { getConfigByName } = useExtensions()

  return getConfigByName('user-kyc') as Kyc.ModuleConfig | null
})
const isKycEnabled = computed(() => Boolean(moduleConfig.value))
const serviceType = computed(() => moduleConfig.value?.service ?? 'stripeId')
const rolesAssignUponRegistration = computed(() => {
  return (moduleConfig.value?.roleAssignUponRegistration ?? '').split(' ')
})

const isPaymentMethodsAddKycRequired = computed(() => {
  return moduleConfig.value?.triggers?.paymentMethods?.['*']?.add ?? false
})

const isPaymentMethodCheckoutKycRequired = computed(() => {
  return moduleConfig.value?.triggers?.paymentMethods?.['*']?.checkout ?? false
})

const isPaymentMethodsSelectKycRequired = (
  paymentMethodType: ActivityItemPaymentMethodType
) => {
  return (
    moduleConfig.value?.triggers?.paymentMethods?.[paymentMethodType]
      ?.checkout ?? false
  )
}

const isPayeesAddKycRequired = computed(() => {
  return moduleConfig.value?.triggers?.payees?.['*']?.add ?? false
})

emitter.on(
  'notifications:identity_verification.successful',
  verificationUpdateHandler
)
emitter.on(
  'notifications:identity_verification.fail',
  verificationUpdateHandler
)

function verificationUpdateHandler() {
  if (!isKycEnabled.value) {
    return
  }

  const { fetchUserDetails } = useUser()

  fetchUserDetails()
}

export function useKyc() {
  return {
    isKycEnabled,
    serviceType,
    rolesAssignUponRegistration,
    isPaymentMethodsAddKycRequired,
    isPayeesAddKycRequired,
    isPaymentMethodCheckoutKycRequired,
    isPaymentMethodsSelectKycRequired,
  }
}

export function useKycComponent() {
  let kycService = KycServiceFactory.create(serviceType.value)

  watch(
    () => isKycEnabled,
    async (value) => {
      if (value) {
        kycService = KycServiceFactory.create(serviceType.value)
        await kycService.syncVerificationSessions()
      }
    }
  )

  onMounted(async () => {
    if (isKycEnabled.value) {
      await kycService.syncVerificationSessions()
    }
  })

  return {
    kycService,
  }
}

import { computed, ref } from 'vue'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { usePayeeCheckout } from '/~/composables/checkout/use-payee-checkout'
import { usePurchaseCheckout } from '/~/composables/checkout/use-purchase-checkout'
import { useQuickBuyCheckout } from '/~/composables/checkout/use-quickbuy-checkout'
import { useStatementCheckout } from '/~/composables/checkout/use-statement-checkout'

const currentFlowType = ref<FlowType>(FlowType.purchase)

const isPaymentCurrentFlowType = computed(
  () => currentFlowType.value === FlowType.payment
)
const isPurchaseCurrentFlowType = computed(
  () => currentFlowType.value === FlowType.purchase
)
const isQuickBuyCurrentFlowType = computed(
  () => currentFlowType.value === FlowType.quickBuy
)
const isStatementCurrentFlowType = computed(() => {
  return currentFlowType.value === FlowType.statement
})
const isBatchOrderCurrentFlowType = computed(() => {
  return currentFlowType.value === FlowType.batch
})
const isPurchaseGroupCurrentFlowType = computed(() => {
  return (
    currentFlowType.value === FlowType.purchase ||
    currentFlowType.value === FlowType.quickBuy
  )
})

const composable = computed(() => {
  if (isStatementCurrentFlowType.value) {
    return useStatementCheckout
  } else if (isPaymentCurrentFlowType.value) {
    return usePayeeCheckout
  } else if (isQuickBuyCurrentFlowType.value) {
    return useQuickBuyCheckout
  } else {
    return usePurchaseCheckout
  }
})

export function useCheckout() {
  return {
    ...composable.value(),
    currentFlowType,
    isPaymentCurrentFlowType,
    isPurchaseCurrentFlowType,
    isQuickBuyCurrentFlowType,
    isStatementCurrentFlowType,
    isBatchOrderCurrentFlowType,
    isPurchaseGroupCurrentFlowType,
  }
}

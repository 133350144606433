export const States = [
  {
    id: 'AL',
    label: 'Alabama',
    placeId: 'ChIJdf5LHzR_hogR6czIUzU0VV4',
  },
  {
    id: 'AK',
    label: 'Alaska',
    placeId: 'ChIJG8CuwJzfAFQRNduKqSde27w',
  },
  {
    id: 'AZ',
    label: 'Arizona',
    placeId: 'ChIJaxhMy-sIK4cRcc3Bf7EnOUI',
  },
  {
    id: 'AR',
    label: 'Arkansas',
    placeId: 'ChIJYSc_dD-e0ocR0NLf_z5pBaQ',
  },
  {
    id: 'CA',
    label: 'California',
    placeId: 'ChIJPV4oX_65j4ARVW8IJ6IJUYs',
  },
  {
    id: 'CO',
    label: 'Colorado',
    placeId: 'ChIJt1YYm3QUQIcR_6eQSTGDVMc',
  },
  {
    id: 'CT',
    label: 'Connecticut',
    placeId: 'ChIJpVER8hFT5okR5XBhBVttmq4',
  },
  {
    id: 'DE',
    label: 'Delaware',
    placeId: 'ChIJO9YMTXYFx4kReOgEjBItHZQ',
  },
  {
    id: 'FL',
    label: 'Florida',
    placeId: 'ChIJvypWkWV2wYgR0E7HW9MTLvc',
  },
  {
    id: 'GA',
    label: 'Georgia',
    placeId: 'ChIJV4FfHcU28YgR5xBP7BC8hGY',
  },
  {
    id: 'HI',
    label: 'Hawaii',
    placeId: 'ChIJBeB5Twbb_3sRKIbMdNKCd0s',
  },
  {
    id: 'ID',
    label: 'Idaho',
    placeId: 'ChIJ6Znkhaj_WFMRWIf3FQUwa9A',
  },
  {
    id: 'IL',
    label: 'Illinois',
    placeId: 'ChIJGSZubzgtC4gRVlkRZFCCFX8',
  },
  {
    id: 'IN',
    label: 'Indiana',
    placeId: 'ChIJHRv42bxQa4gRcuwyy84vEH4',
  },
  {
    id: 'IA',
    label: 'Iowa',
    placeId: 'ChIJGWD48W9e7ocR2VnHV0pj78Y',
  },
  {
    id: 'KS',
    label: 'Kansas',
    placeId: 'ChIJawF8cXEXo4cRXwk-S6m0wmg',
  },
  {
    id: 'KY',
    label: 'Kentucky',
    placeId: 'ChIJyVMZi0xzQogR_N_MxU5vH3c',
  },
  {
    id: 'LA',
    label: 'Louisiana',
    placeId: 'ChIJZYIRslSkIIYRA0flgTL3Vck',
  },
  {
    id: 'ME',
    label: 'Maine',
    placeId: 'ChIJ1YpTHd4dsEwR0KggZ2_MedY',
  },
  {
    id: 'MD',
    label: 'Maryland',
    placeId: 'ChIJ35Dx6etNtokRsfZVdmU3r_I',
  },
  {
    id: 'MA',
    label: 'Massachusetts',
    placeId: 'ChIJ_b9z6W1l44kRHA2DVTbQxkU',
  },
  {
    id: 'MI',
    label: 'Michigan',
    placeId: 'ChIJEQTKxz2qTE0Rs8liellI3Zc',
  },
  {
    id: 'MN',
    label: 'Minnesota',
    placeId: 'ChIJmwt4YJpbWE0RD6L-EJvJogI',
  },
  {
    id: 'MS',
    label: 'Mississippi',
    placeId: 'ChIJGdRK5OQyKIYR2qbc6X8XDWI',
  },
  {
    id: 'MO',
    label: 'Missouri',
    placeId: 'ChIJfeMiSNXmwIcRcr1mBFnEW7U',
  },
  {
    id: 'MT',
    label: 'Montana',
    placeId: 'ChIJ04p7LZwrQVMRGGwqz1jWcfU',
  },
  {
    id: 'NE',
    label: 'Nebraska',
    placeId: 'ChIJ7fwMtciNk4cRxArzDwyQJ6E',
  },
  {
    id: 'NV',
    label: 'Nevada',
    placeId: 'ChIJcbTe-KEKmYARs5X8qooDR88',
  },
  {
    id: 'NH',
    label: 'New Hampshire',
    placeId: 'ChIJ66bAnUtEs0wR64CmJa8CyNc',
  },
  {
    id: 'NJ',
    label: 'New Jersey',
    placeId: 'ChIJn0AAnpX7wIkRjW0_-Ad70iw',
  },
  {
    id: 'NM',
    label: 'New Mexico',
    placeId: 'ChIJqVKY50NQGIcRup41Yxpuv0Y',
  },
  {
    id: 'NY',
    label: 'New York',
    placeId: 'ChIJOwg_06VPwokRYv534QaPC8g',
  },
  {
    id: 'NC',
    label: 'North Carolina',
    placeId: 'ChIJgRo4_MQfVIgRGa4i6fUwP60',
  },
  {
    id: 'ND',
    label: 'North Dakota',
    placeId: 'ChIJY-nYVxKD11IRyc9egzmahA0',
  },
  {
    id: 'OH',
    label: 'Ohio',
    placeId: 'ChIJwY5NtXrpNogRFtmfnDlkzeU',
  },
  {
    id: 'OK',
    label: 'Oklahoma',
    placeId: 'ChIJnU-ssRE5rIcRSOoKQDPPHF0',
  },
  {
    id: 'OR',
    label: 'Oregon',
    placeId: 'ChIJVWqfm3xuk1QRdrgLettlTH0',
  },
  {
    id: 'PA',
    label: 'Pennsylvania',
    placeId: 'ChIJieUyHiaALYgRPbQiUEchRsI',
  },
  {
    id: 'RI',
    label: 'Rhode Island',
    placeId: 'ChIJD9cOYhQ15IkR5wbB57wYTh4',
  },
  {
    id: 'SC',
    label: 'South Carolina',
    placeId: 'ChIJ49ExeWml-IgRnhcF9TKh_7k',
  },
  {
    id: 'SD',
    label: 'South Dakota',
    placeId: 'ChIJpTjphS1DfYcRt6SGMSnW8Ac',
  },
  {
    id: 'TN',
    label: 'Tennessee',
    placeId: 'ChIJA8-XniNLYYgRVpGBpcEgPgM',
  },
  {
    id: 'TX',
    label: 'Texas',
    placeId: 'ChIJSTKCCzZwQIYRPN4IGI8c6xY',
  },
  {
    id: 'UT',
    label: 'Utah',
    placeId: 'ChIJzfkTj8drTIcRP0bXbKVK370',
  },
  {
    id: 'VT',
    label: 'Vermont',
    placeId: 'ChIJ_87aSGzctEwRtGtUNnSJTSY',
  },
  {
    id: 'VA',
    label: 'Virginia',
    placeId: 'ChIJzbK8vXDWTIgRlaZGt0lBTsA',
  },
  {
    id: 'WA',
    label: 'Washington',
    placeId: 'ChIJW-T2Wt7Gt4kRKl2I1CJFUsI',
  },
  {
    id: 'WV',
    label: 'West Virginia',
    placeId: 'ChIJRQnL1KVUSogRQzrN3mjHALs',
  },
  {
    id: 'WI',
    label: 'Wisconsin',
    placeId: 'ChIJr-OEkw_0qFIR1kmG-LjV1fI',
  },
  {
    id: 'WY',
    label: 'Wyoming',
    placeId: 'ChIJaS7hSDTiXocRLzh90nkisCY',
  },
  {
    id: 'DC',
    label: 'District of Columbia',
    placeId: 'ChIJW-T2Wt7Gt4kRmKFUAsCO4tY',
  },
  {
    id: 'AS',
    label: 'American Samoa',
    placeId: 'ChIJyf1IkreEpnER3UuuLZ5z7vM',
  },
  {
    id: 'GU',
    label: 'Guam',
    placeId: 'ChIJERFQE6VteIgRyMN6gXCH8NM',
  },
  {
    id: 'MP',
    label: 'Northern Mariana Islands',
    placeId: 'ChIJUVyZkDSx2GYRMg46fKvrt50',
  },
  {
    id: 'PR',
    label: 'Puerto Rico',
    placeId: 'ChIJ-aeSGyaWAowRGpsEGCjsNvM',
  },
  {
    id: 'UM',
    label: 'United States Minor Outlying Islands',
    placeId: 'ChIJBQ-2twOlemQREDZxw68t4QQ',
  },
  {
    id: 'VI',
    label: 'Virgin Islands, U.S.',
    placeId: 'ChIJY3-lUpLs2oAR1vs3mrb-IpA',
  },
]

<script setup lang="ts">
import { ref } from 'vue'
import BaseButton from '/~/components/base/button/base-button.vue'

const props = withDefaults(
  defineProps<{
    title?: string
    padding?: 'sm' | 'xs' | boolean
    shadow?: boolean
    titleCenter?: boolean
    closable?: boolean
    showCloseIcon?: boolean
    maxHeight?: 'screen'
    headerHidden?: boolean
    headerBorder?: boolean
  }>(),
  {
    padding: 'sm',
    title: '',
    shadow: true,
    titleCenter: true,
    closable: true,
    showCloseIcon: true,
    headerHidden: false,
    headerBorder: false,
  }
)

const emit = defineEmits<(event: 'hide') => void>()

const pinned = ref(false)

function onBodyScroll({ target }: { target: HTMLElement }) {
  pinned.value = props.shadow && target?.scrollTop > 0
}

function onClose() {
  if (props.closable) {
    emit('hide')
  }
}
</script>

<template>
  <div
    class="relative flex h-full w-full flex-col"
    :class="{ 'max-h-screen-90': maxHeight === 'screen' }"
    @keyup.esc="onClose"
  >
    <slot v-if="!headerHidden" name="header">
      <header
        class="relative z-40 flex flex-col transition duration-200"
        :class="{
          'shadow-md': pinned,
          'border-b': headerBorder,
        }"
      >
        <div class="flex flex-col items-center justify-center p-6">
          <div v-if="$slots.icon" class="mb-4">
            <slot name="icon" />
          </div>
          <h2
            v-if="title || $slots.title"
            class="text-2xl text-eonx-neutral-800"
            :class="titleCenter ? 'mx-auto' : 'mr-auto'"
          >
            <slot name="title">
              {{ title }}
            </slot>
          </h2>
          <div
            class="absolute right-5 top-6 flex items-center sm:right-6 sm:top-6"
          >
            <slot name="menu" />
            <base-button
              v-if="showCloseIcon"
              ref="closeButton"
              icon="heroicons/solid/x-mark"
              :size="32"
              alt="close"
              class="rounded-full"
              data-test="modal-close"
              :disabled="!closable"
              @click="onClose"
            />
          </div>
        </div>
        <slot name="header-bottom" />
      </header>
    </slot>

    <div
      class="popup-layout flex-1 overflow-y-auto overflow-x-hidden"
      :class="{
        'p-0': padding === false,
        'px-5 pb-5': padding === 'xs',
        'px-6 pb-6': padding === 'sm',
      }"
      @scroll="onBodyScroll"
    >
      <slot />
    </div>
    <footer v-if="$slots.footer">
      <slot name="footer" />
    </footer>
  </div>
</template>

export function formatBusinessNumber(value: string) {
  const digits = String(value).replace(/\D/g, '')

  const matches = digits.match(/^(\d{2})(\d{7})$/)

  if (matches) {
    return `${matches[1]}-${matches[2]}`
  }
  return value
}

export function parseBsb(
  value: string | number,
  options = {
    showLabel: false,
  }
) {
  const result: Record<string, string | null> = { bsb: null }

  if (!value) {
    return result
  }

  const digits = String(value).replace(/\D/g, '')

  if (digits.length !== 9) {
    return result
  }

  result.bsb = (options.showLabel ? 'Routing Number: ' : '') + digits
  return result
}

export function formatBsb(value: string | number) {
  if (value) {
    const matches = String(value).match(/.{1,3}/g)

    if (matches) {
      return matches.join('-')
    }
  }

  return value
}

export const bsbLength = 9

export function postfixBsb(value: string) {
  return value.length === bsbLength ? value.slice(-3) : ''
}

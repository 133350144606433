import { OrderResponse, PurchaseOrder } from '/~/types/api'
import { ActivityItemMetadataStatus } from '/~/composables/activity/activity-enums'
import { ActivityStatus } from '/~/composables/activity/core/ActivityStatus'
import { getFlowTypeFromOrderNumber } from '/~/composables/checkout/checkout-types'

export class Order extends ActivityStatus {
  raw: OrderResponse
  constructor(private order: OrderResponse) {
    const status = order.status?.toLowerCase() as ActivityItemMetadataStatus
    const financialStatus = (
      order as PurchaseOrder
    ).financialStatus?.toLowerCase()
    const scheduledAt = order.scheduledAt || undefined
    const flowType =
      typeof order.number === 'string'
        ? getFlowTypeFromOrderNumber(order.number)
        : undefined

    super(status, {
      financialStatus,
      scheduledAt,
      flowType,
    })
    this.raw = order
    Object.assign(this, { ...order, status })
  }
}
